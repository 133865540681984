<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="khaznaTransactionModal"
      :title="type === 'add' ? `${$t('Add Khazna Transaction')}` : `${$t('Edit Khazna Transaction')}`"
      :buttons-hidden="true"
    >
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input
            v-validate="'required'"
            :label-placeholder="$t('Amount')"
            key="amount"
            name="amount"
            v-model="transaction.amount"
            class="mt-10 w-full"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('amount')"
            >{{ errors.first("amount") }}</span
          >
        </div>
        <div class="vx-col w-1/2">
          <v-select
            autocomplete="nofill"
            class="select-large mt-10 w-full"
            v-model="transaction.type"
            v-validate="'required'"
            key="type"
            name="type"
            :placeholder="$t('Type')"
            label="name"
            :options="$store.state.AppActiveUser.userRole === 'finance' ? financeTypes : types"
          />
          <span class="text-danger text-sm" v-show="errors.has('type')">{{
            errors.first("type")
          }}</span>
        </div>
      </div>
      <div v-if="transaction.type && transaction.type.value === 'withdraw'">
        <input name="file" type="file" @change="changeImage" class="hidden" ref="fileInput">
        <div
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="w-4/5 m-auto mt-10 mb-10 px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <div v-if="image === ''">
            <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
            <span>{{$t('Drop File or')}} </span>
            <span class="font-medium text-primary" @click.stop="$refs.fileInput.click()">{{$t('Browse')}}</span>
          </div>
          <div v-else>
            <img class="w-20 h-20" :src="image"/>
          </div>
        </div>
        <span class="text-danger text-sm" v-show="!image">This field is required</span>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <datepicker
            :disabledDates="disabledDates"
            v-validate="'required'" 
            name="deposit date" 
            :placeholder="$t('Deposit Date') + '*'" 
            v-model="transaction.deposit_date"  
            class="mt-10 w-full mb-16">
          </datepicker>
          <span class="text-danger text-sm" v-show="errors.has('deposit date')">{{
            errors.first("deposit date")
          }}</span>
        </div>
        <div class="vx-col w-1/2">
          <vs-input
            v-validate="'required'"
            :label-placeholder="$t('Reference Number')"
            key="reference number"
            name="reference number"
            v-model="transaction.reference_number"
            class="mt-10 w-full"
          />
          <span class="text-danger text-sm" v-show="errors.has('reference number')">{{
            errors.first("reference number")
          }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button v-if="type === 'add'" @click="addKhaznaTransaction" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['khaznaTransactionModal', 'khazna', 'type'],
  data () {
    return {
      disabledDates: {
        from: new Date()
      },
      types: [
        {
          name: this.$t('Deposit at Warehouse'),
          value: 'deposit'
        },
        {
          name: this.$t('Withdraw'),
          value: 'withdraw'
        }
      ],
      financeTypes : [
        {
          name: this.$t('Adjustment'),
          value: 'adjustment'
        }
      ],
      transaction: {
        amount: '',
        type: '',
        by: '',
        reference_number: '',
        deposit_date: ''
      },
      image: '',
      file_key: '',
      userInfo: {},
      agents: [],
      agentsDic: {}
    }
  },
  watch: {
    'khaznaTransactionModal' () {
      this.disabledDates = {
        from: new Date()
      }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    loadAgents () {
      sendRequest(false, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/agents/customer_agent/?limit=100`, 'get', null, true, 
        (response) => {
          this.agents = response.data.results
          this.agents.forEach(element => {
            this.agentsDic[element.id] = element
            element.fullName = `${element.first_name} ${element.last_name} - ${element.hr_id}`
          })
        }
      )
    },
    addKhaznaTransaction () {
      this.$validator.validateAll().then(result => {
        if ((result &&  (this.transaction.type.value === 'deposit' || this.transaction.type.value === 'adjustment')) || (result &&  this.transaction.type.value === 'withdraw' && this.image !== '')) {
          this.$vs.loading()
          const data = new Date(this.transaction.deposit_date).toISOString()
          const transaction = {
            amount: this.transaction.amount,
            deposit_date: data.split('T')[0],
            type: this.transaction.type.value,
            by: this.userInfo.name,
            reference_number: this.transaction.reference_number,
            deposit_slip_key: this.file_key
          }
          sendRequest(false, false, this, `api/v1/accounting/khazna/${this.khazna.id}/transactions/`, 'post', transaction, true,
            () => {
              this.$validator.pause()
              this.image = ''
              this.$vs.loading.close()
              this.closeModal()
            }
          )
        }
      })
    },
    closeModal () {
      this.$validator.pause()
      this.transaction = {}
      this.$emit('khaznaTransactionModal', false)
      this.$emit('loadTransactions')
    },
    changeImage () {
      this.$vs.loading()
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.image = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', this.$refs.fileInput.files[0])
      sendRequest(false, false, this, 'api/v1/upload-bank-deposit-file/', 'post', formData, true,
        (response) => {
          this.file_key = response.data.file_key
        }
      )
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    }
  },
  created () {
    this.userInfo = localStorage.getItem('idTokenPayload') ? JSON.parse(localStorage.getItem('idTokenPayload')) : {}
    this.loadAgents()
  },
  components: {
    ShipbluPrompt,
    vSelect,
    Datepicker
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
