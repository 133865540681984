<template>
  <div> 
    <shipblu-table orders v-model="selected" multiple :sst="true" :data="withdrawRequests" :tableLoader="tableLoader">
      <template slot="thead">
        <shipblu-th>{{$t('Created')}}</shipblu-th>
        <shipblu-th>{{$t('Comment')}}</shipblu-th>
        <shipblu-th>{{$t('Requested Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Deposited Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Deposit Date')}}</shipblu-th>
        <shipblu-th>{{$t('By')}}</shipblu-th>
        <shipblu-th>{{$t('Reference Number')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Action')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].id">
            {{new Date(data[indextr].created).toLocaleDateString('fr-CA')}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].by">
            <div v-if="data[indextr].comment && data[indextr].comment.split('|').length > 0">
              <p v-for="item in String(data[indextr].comment).split('|')" :key="item.index">{{ item }}</p>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].requested_amount">
            {{ data[indextr].requested_amount ? data[indextr].requested_amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].deposited_amount">
            {{ data[indextr].deposited_amount ? data[indextr].deposited_amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].created">
            <p>{{ new Date(data[indextr].deposit_date).toLocaleDateString('fr-CA') }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].by">
            {{ data[indextr].by }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].reference_number">
            {{ data[indextr].reference_number}}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{$t(data[indextr].status)}}</span>
            </div>
          </shipblu-td>

          <shipblu-td>
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">
                <vs-dropdown-item
                  :disabled="data[indextr].status === 'cancelled' || data[indextr].status === 'completed'"
                  @click="cancelDeposit(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="XCircleIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Cancel')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination  :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import i18nData from '../../../i18n/i18nData.js'
import { common } from '../../admin/Orders.vue'

export default {
  props: ['warehousesDic'],
  data () {
    return {
      selected: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      cancelData: {},
      withdrawRequests: []
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          filter: '',
          page: this.currentPage,
          filterTab: this.$route.query.filterTab
        }
      }).catch(() => {})
      this.loadWithdrawRequest()
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    cancelDeposit (data) {
      this.cancelData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to Cancel this transaction?'],
        accept: this.updateWithdrawRequest
      })
    },
    loadWithdrawRequest () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/accounting/khazna/${this.warehousesDic[this.$route.params.warehouseID]}/withdraw-requests/?offset=${this.offset}&limit=${this.maximumItems}`, 'get', null, true, 
        (response) => {
          this.withdrawRequests = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    updateWithdrawRequest () {
      sendRequest(false, false, this, `api/v1/accounting/khazna/${this.warehousesDic[this.$route.params.warehouseID]}/withdraw-requests/${this.cancelData.id}/`, 'patch', {status:'cancelled'}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Deposit Request '] + i18nData[this.$i18n.locale]['has been updated successfully!'],
            position: 'top-center'
          })
          this.loadWithdrawRequest()
        }
      )
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadWithdrawRequest()
  }
}
</script>
