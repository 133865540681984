<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{khazna.name ? khazna.name.split(' ')[0] : ''}} {{$t('Khazna Transactions')}}</h2>
      </div>
      <div class="flex vx-col w-full lg:w-1/2 items-end">
        <vs-button class="ml-auto mr-5 mb-4 self-center" color="success" @click="openExportModal">{{$t('Export')}}</vs-button> 
        <div class="flex justify-between mb-4">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="openKhaznaModal('add')"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
            >
            </vs-button>
          </span>
        </div>
      </div>
    </div>
    <div class="mb-8 flex md:flex-row flex-col gap-4">
      <div class="page-title px-6 py-8 flex items-center gap-2">
        <img src="@/assets/images/pages/walletKhazna.svg" width="24" alt="wallet-khazna">
        <div class="lg:text-lg flex gap-2 items-center">
          <span class="text-darkblue font-medium">{{$t('Cash Balance')}}</span> |
          <span class="font-semibold text-primary">{{Number(khazna.balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</span>
        </div>
      </div>
      <div class="page-title px-6 py-8 flex items-center gap-2">
        <img src="@/assets/images/pages/walletKhazna.svg" width="24" alt="wallet-khazna">
        <div class="lg:text-lg flex gap-2 items-center">
          <span class="text-darkblue font-medium">{{$t('Shortfall')}}</span> |
          <span class="font-semibold text-red">{{Number(khazna.cumulative_shortfall).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}} {{$t('EGP')}}</span>
        </div>
      </div>
    </div>
    <div class="relative">
      <div v-if="$route.query.filterTab !== 'Settle-Shortfall' && $route.query.filterTab !== 'Deposits-from-Warehouse'" class="sm:absolute right-0 px-3" style="z-index: 9999;">
        <filter-date modalPosition="right-0" :filterDateModal="filterDateModal" :startDate="startDateFilter" :endDate="endDateFilter" @filterDateModal="filterDateModal = $event" @startDate="startDateFilter = $event" @endDate="endDateFilter = $event" @loadTransactions="loadTransactions"/>
      </div>
      <vs-tabs v-model="activeTab">
        <vs-tab v-for="(tab, index) in tabs" :key="tab.index" :label="$t(tab)">
          <shipblu-table
            v-if="index === 0 || index === 1"
            :sst="true"
            orders
            multiple
            v-model="selected"
            :data="transactions"
            :max-items="maximumItems"
            pagination
            :tableLoader="tableLoader"
          >

            <template slot="thead">
              <shipblu-th>{{$t('ID')}}</shipblu-th>
              <shipblu-th>{{$t('Amount')}}</shipblu-th>
              <shipblu-th>{{$t('Transaction Type')}}</shipblu-th>
              <shipblu-th>{{$t('Issued By')}}</shipblu-th>
              <shipblu-th>{{$t('Deposit Date')}}</shipblu-th>
              <shipblu-th>{{$t('Created On')}}</shipblu-th>
              <shipblu-th>{{$t('Status')}}</shipblu-th>
              <shipblu-th>{{$t('Reference Number')}}</shipblu-th>
              <shipblu-th>{{$t('Transaction Created On')}}</shipblu-th>
              <shipblu-th>{{$t('Download')}}</shipblu-th>
              <shipblu-th>{{$t('Actions')}}</shipblu-th>
            </template>

            <template slot-scope="{ data }">
              <shipblu-tr class="inactive-link" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
                <shipblu-td :data="data[indextr].id">
                  {{ data[indextr].id }}
                </shipblu-td>
                <shipblu-td :data="data[indextr].amount">
                  {{data[indextr].amount ? (data[indextr].amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.00'}}
                </shipblu-td>
                <shipblu-td :data="data[indextr].type">
                  {{ data[indextr].type }}
                </shipblu-td>
                <shipblu-td :data="data[indextr].by">
                  {{ data[indextr].by }}
                </shipblu-td>
                <shipblu-td :data="data[indextr].by">
                  {{ data[indextr].deposit_date }}
                </shipblu-td>
                <shipblu-td :data="data[indextr].by">
                  {{ new Date(data[indextr].created).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }) }}
                </shipblu-td>
                <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
                  <vx-tooltip v-if="data[indextr].status === 'declined'" :text="data[indextr].comment" class="absolute">
                    <div class="m-auto flex items-center justify-center font-semibold">
                      <div><span class="status-icon mr-1"></span></div>
                      <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                    </div>
                  </vx-tooltip>
                  <div v-else class="m-auto flex items-center justify-center font-semibold">
                    <div><span class="status-icon mr-1"></span></div>
                    <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
                  </div>
                </shipblu-td>
                <shipblu-td :data="data[indextr].reference_number">
                  {{ data[indextr].reference_number }}
                </shipblu-td>
                <shipblu-td :data="data[indextr].created">
                  {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
                </shipblu-td>
                <shipblu-td class="active-link" :data="data[indextr].download">
                  <a v-if="data[indextr].type === 'withdraw'" @click="downloadTransaction(data[indextr])" target="_blank">{{$t('Download')}}</a>
                </shipblu-td>
                <shipblu-td class="active-link">
                  <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer"
                    @click="selected = []"
                  >
                    <vs-button
                      type="border"
                      size="small"
                      icon-pack="feather"
                      icon="icon-more-horizontal"
                      class="mr-2"
                    >
                    </vs-button>
                    <vs-dropdown-menu style="width: 190px">
                    <vs-dropdown-item
                      :disabled="data[indextr].status === 'approved' || data[indextr].status === 'declined'"
                        @click="updateTransaction(data[indextr], 'approved')"
                      >
                        <span class="flex items-center">
                          <feather-icon
                            icon="CheckCircleIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>{{$t('Approved')}}</span>
                        </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item
                      :disabled="data[indextr].status === 'declined' || data[indextr].status === 'approved'"
                        @click="openCommentModal(data[indextr])"
                      >
                        <span class="flex items-center">
                          <feather-icon
                            icon="XCircleIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>{{$t('Declined')}}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </shipblu-td>
              </shipblu-tr>
            </template>
          </shipblu-table>
          <div v-else-if="index === 2">
            <deposits-from-warehouse :warehousesDic="warehousesDic" :khazna="khazna"></deposits-from-warehouse>
          </div>
          <div v-else>
            <agent-shortfall></agent-shortfall> 
          </div>
          <shipblu-pagination v-if="index === 0 || index === 1" :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
        </vs-tab>
      </vs-tabs>
    </div>
    <add-khazna-transaction :khaznaTransactionModal="khaznaTransactionModal" @khaznaTransactionModal="khaznaTransactionModal = $event" :khazna="khazna" :type="type" @loadTransactions="loadKhazna"></add-khazna-transaction>
   <div>
      <shipblu-prompt
        class="shipment-modal"
        @close="closeModal"
        :active.sync="exportModal"
        :title="$t('Export')"
        :buttons-hidden="true">
        <div>
          <div class="vx-row mb-4">
            <div class="vx-col w-full">
              <v-select name="criteria" v-model="criteria" :placeholder="$t('Criteria')" label="name" :options="types"/>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-1/2">
              <label class="font-semibold">{{$t('From')}}:</label>
              <datepicker :inline="true" v-validate="'required'" name="from" :disabledDates="disabledDatesStart" v-model="startDate" class="mt-1"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <label class="font-semibold">{{$t('To')}}:</label>
              <datepicker :inline="true" v-validate="'required'" name="to" :disabledDates="disabledDates" v-model="endDate" class="mt-1"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
            </div>
          </div>
        </div>
        <vs-button type="filled" @click="sendExport" class="float-right m-5 block">{{$t('Send')}}</vs-button>
        <vs-button type="filled" color="danger" class="float-right m-5 block" @click="closeModal">{{$t('Cancel')}}</vs-button>
      </shipblu-prompt>
    </div>
    <div>
      <shipblu-prompt
        class="shipment-modal"
        @close="commentModal = false"
        :active.sync="commentModal"
        :title="$t('Add Comment')"
        :buttons-hidden="true">
        <div class="vx-row">
          <div class="vx-col w-full">
            <label class="font-semibold">{{$t('Comment')}}:</label>
            <vs-input v-validate="'required'" class="w-full" :label-placeholder="$t('Comment')" name="comment" v-model="comment"/>
            <span class="text-danger text-sm" v-show="errors.has('comment')">{{ errors.first('comment') }}</span>
          </div>
        </div>
        <vs-button type="filled" @click="updateTransaction(transaction, 'declined')" class="float-right m-5 block">{{$t('Save')}}</vs-button>
        <vs-button type="filled" color="danger" class="float-right m-5 block" @click="commentModal = false">{{$t('Cancel')}}</vs-button>
      </shipblu-prompt>
    </div>
   </div>
</template>

<script>
import AddKhaznaTransaction from '../headOfFleet/components/AddKhaznaTransaction.vue'
import common  from '@/assets/utils/common'
import i18nData from '../../i18n/i18nData'
import {sendRequest} from '../../http/axios/requestHelper.js'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import HeadComponent from '../merchant/components/Header.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import AgentShortfall from '../headOfFleet/components/AgentShortfall.vue'
import DepositsFromWarehouse from '../headOfFleet/components/DepositsfromWarehouse.vue'
import FilterDate from '../finance/components/FilterDate.vue'

export default {
  data () {
    return {
      selected: [],
      transactions: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      khaznaTransactionModal: false,
      khazna: {},
      type: '',
      warehousesDic: {},
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      tabs: ['Review Required', 'All', 'Deposits from Warehouse', 'Settle Shortfall'],
      tabsDic: {0: 'Review-Required', 1: 'All', 2: 'Deposits-from-Warehouse', 3: 'Settle-Shortfall'},
      tabsDicRevers: {'Review-Required': 0, 'All': 1, 'Deposits-from-Warehouse':2, 'Settle-Shortfall':3 },
      activeTab: 0,
      disabledDates: {
        to: new Date() // Disable all dates up to specific date
      },
      disabledDatesStart: {
        from: new Date() // Disable all dates up to specific date
      },
      exportModal: false,
      startDate: '',
      endDate: '',
      criteria: '',
      types: [
        {
          name: 'Withdraw From Warehouse',
          value: 'withdraw'
        }
      ],
      comment: '',
      transaction: {},
      tableLoader: false,
      commentModal: false,
      agentsWithShortfall: [],
      agents: [],
      allAgents: [],
      filters: '',
      shortfallSettlementModal: false,
      filterDateModal: false,
      startDateFilter: '',
      endDateFilter: ''
    }
  },
  watch: {
    '$route.query.filterTab' () {
      this.currentPage = 1
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          filter: this.filters,
          page: this.currentPage,
          filterTab: this.tabsDic[this.activeTab]
        }
      }).catch(() => {})
      if (this.$route.query.filterTab === 'All') {
        this.loadTransactions()
      }
    },
    '$route.params.warehouseID' () {
      this.loadKhazna()
    },
    'activeTab' () {
      this.currentPage = Number(this.$route.query.page) === Number(this.currentPage) ? this.currentPage : 1
      this.$router.push({
        query: {
          filter: this.filters,
          page: this.currentPage,
          filterTab: this.tabsDic[this.activeTab]
        }
      }).catch(() => {})
      this.loadTransactions()
    },
    'startDate' () {
      const date = new Date(this.startDate)
      this.disabledDates.to = date
      const endDate = new Date()
      this.disabledDates.from = new Date(endDate.setDate(date.getDate() + 45))
    }
  },
  methods: {
    openExportModal () {
      this.exportModal = true
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.code] = warehouse.id
      })
      this.loadKhazna()
    },
    loadKhazna () {
      sendRequest(false, false, this, `api/v1/accounting/khazna/${this.warehousesDic[this.$route.params.warehouseID]}/`, 'get', null, true, 
        (response) => {
          this.khazna = response.data
          this.loadTransactions()
        }
      )
    },
    sendExport () {
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(false, false, this, `api/v1/analytics/warehouse-khazna/${this.$route.params.warehouseID}/export/?transaction_type=${this.criteria ? this.criteria.value : ''}&from=${new Date(this.startDate).toISOString().split('T')[0]}&to=${new Date(this.endDate).toISOString().split('T')[0]}`, 'get', null, true, 
            (response) => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text: response.data.message,
                position: 'top-center'
              })
              this.closeModal()
            }
          )
        }
      })
    },
    loadTransactions () {
      const from = this.startDateFilter ? new Date(this.startDateFilter).toISOString().split('T')[0] : ''
      const to = this.endDateFilter ? new Date(this.endDateFilter).toISOString().split('T')[0] : ''
      let query = `?offset=${this.offset}&limit=${this.maximumItems}&date_from=${from}&date_to=${to}`
      if (this.$store.state.AppActiveUser.userRole === 'finance' && this.activeTab === 0) {
        query = `${query}&status=pending`
      }
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/accounting/khazna/${this.warehousesDic[this.$route.params.warehouseID]}/transactions/${query}`, 'get', null, true, 
        (response) => {
          this.transactions = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
        }
      )
    },
    openKhaznaModal (type) {
      this.type = type
      this.khaznaTransactionModal = true
    },
    downloadTransaction (data) {
      if (data.deposit_slip_key === '#') {
        this.$vs.notify({
          color:'warning',
          title:i18nData[this.$i18n.locale]['Warning'],
          text:i18nData[this.$i18n.locale]['No Slip Available'],
          position: 'top-center'
        })
      } else {
        sendRequest(false, false, this, `api/v1/accounting/khazna-bank-deposits/${data.id}/pdf/`, 'get', null, true, 
          (response) => {
            const file_URl = response.data.file_url
            window.open(file_URl, '_blank')
          }
        )
      }
    },
    openCommentModal (data) {
      this.commentModal =  true
      this.comment = ''
      this.transaction = data
    },
    updateTransaction (data, status) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const transactionObj = {
            status,
            comment: this.comment ? this.comment : null 
          }
          sendRequest(false, false, this, `api/v1/accounting/khazna/${this.warehousesDic[this.$route.params.warehouseID]}/transactions/${data.id}/`, 'patch', transactionObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Transaction'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.commentModal = false
              this.loadTransactions()
            }
          )
        }
      })
    },
    loadAgents () {
      sendRequest(false, false, this, `api/v1/warehouse/${this.$route.params.warehouseID}/agents/customer_agent/?limit=100`, 'get', null, true, 
        (response) => {
          this.agents = response.data.results
          this.agentsWithShortfall = this.agents.filter(item => item.cumulative_shortfall > 0)
          this.agentsWithShortfall.unshift({
            first_name: 'All',
            id: ''
          })
        }
      )
    },
    closeModal () {
      this.$validator.pause()
      this.exportModal = false
      this.startDate = ''
      this.endDate = ''
      this.criteria = ''
    }
  },
  components: {
    AddKhaznaTransaction,
    Datepicker,
    vSelect,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPrompt,
    HeadComponent,
    ShipbluPagination,
    AgentShortfall,
    DepositsFromWarehouse,
    FilterDate
  },
  created () {
    this.activeTab = this.tabsDicRevers[this.$route.query.filterTab] ? this.tabsDicRevers[this.$route.query.filterTab] : '0'
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>