<template>
  <div class="relative">
    <div @click="filterDateModal = true" class="border border-solid border-grey-light bg-white shadow-none rounded py-2 px-3 h-10 flex items-center justify-between gap-1 cursor-pointer">
      <div class="flex items-center gap-2">
        <span class="material-symbols-rounded text-lg text-grey-dark w-4 h-4 select-none">calendar_today</span>
        <span v-if="!startDate || filterDateModal">{{ $t('Date') }}</span>
        <span v-else class="leading-loose">{{ gettingDate(new Date(startDate)) + ' - ' + gettingDate(new Date(endDate)) }}</span>
      </div>
      <span class="material-symbols-rounded text-base select-none">keyboard_arrow_down</span>
    </div>
    <div v-if="filterDateModal" :class="`absolute ${modalPosition} mt-1 p-4 rounded bg-white border border-solid border-grey-light`" style="z-index: 9999;">
      <div class="flex xl:flex-row flex-col gap-4">
        <div class="col-span-1">
          <p class="mb-2 leading-tight">{{ $t('From') + ':' }}</p>
          <datepicker :inline="true" name="from" :disabledDates="disabledFilterStartDates" v-model="startDate"></datepicker>
        </div>
        <div class="col-span-1">
          <p class="mb-2 leading-tight">{{ $t('To') + ':' }}</p>
          <datepicker :inline="true" name="to" :disabledDates="disabledFilterEndDates" v-model="endDate"></datepicker>
        </div>
      </div>
      <div class="flex justify-evenly mt-1">
        <p class="text-blue-900 date-border p-2">{{startDate ? gettingDate(new Date(startDate)) : $t('No Date')}}</p>
        <div class="flex" :style="$i18n.locale === 'ar' ? 'transform: rotate(180deg);' : ''">
          <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
          <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
        </div>
        <p class="text-blue-900 date-border p-2">{{endDate ? gettingDate(new Date(endDate)) : $t('No Date')}}</p>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-6">
        <vs-button @click="closeFilterDateModal('clear')" color="secondary" type="border" class="px-4 rounded">{{ $t('Clear') }}</vs-button>
        <vs-button @click="closeFilterDateModal()" :disabled="!startDate || !endDate" color="primary" class="py-2 px-4 rounded">{{ $t('Filter') }}</vs-button>
      </div>
    </div>
    <div :style="filterDateModal ? 'position: fixed; inset: 0; z-index: 999;' : ''"></div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  props: ['modalPosition', 'filterDateModal', 'startDate', 'endDate'],
  data () {
    return {
      disabledFilterStartDates: {
        from: new Date()
      },
      disabledFilterEndDates: {
        from: new Date(),
        to: new Date() // Disable all dates up to specific date
      }
    }
  },
  watch: {
    'filterDateModal' () {
      this.disabledFilterStartDates = {
        from: new Date()
      }
      this.disabledFilterEndDates = {
        from: new Date(),
        to: new Date(this.startDate)
      }
    },
    startDate () {
      const date = new Date(this.startDate)
      this.endDate = this.startDate ? new Date(this.startDate) : ''
      this.disabledFilterEndDates.to = date
    }
  },
  methods: {
    gettingDate (date, formateDate) {
      const locale = (localStorage.getItem('locale') && localStorage.getItem('locale') !== 'null') ? localStorage.getItem('locale') : 'ar'
      const options = {day: '2-digit', month: 'short', year: 'numeric'}
      formateDate = new Date(date).toLocaleDateString(locale, options)
      return formateDate
    },
    closeFilterDateModal (type) {
      if (type === 'clear') {
        this.$emit('startDate', '')
        this.$emit('endDate', '')
      } else {
        this.$emit('startDate', this.startDate)
        this.$emit('endDate', this.endDate)
      }
      this.$emit('filterDateModal', false)
      this.$emit('loadTransactions')
    }
  },
  components: {
    Datepicker
  }
}
</script>